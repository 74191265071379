/* eslint-disable react/no-unescaped-entities */
import Button from "@components/application/Button"
import emailjs from "@emailjs/browser"
import { Download } from "@modules/application"
import AOS from "aos"
import "aos/dist/aos.css"
import { GoogleSpreadsheet } from "google-spreadsheet"
import { useRouter } from "next/router"
import { useEffect, useRef, useState } from "react"
import { useGetRatesQuery } from "services/ratesApi"
import { Container } from "./styles"
// const { GoogleSpreadsheet } = require("google-spreadsheet")

const needs = [
  {
    title: "Multi-Currency Wallet",
    det: "Keep track of your money all in one place. Buy , sell and store fiat and digital currency",
    img: "https://res.cloudinary.com/gigx-technologies/image/upload/v1669272445/xpad/Group_24472_2x_mgzewr.png",
    color: "#fffced",
  },
  {
    title: "Cross-Border Payment",
    det: "Distance is no more a barrier. Send to and receive money seamlessly from family, friends, businesses and institutions",
    img: "https://res.cloudinary.com/gigx-technologies/image/upload/v1669272667/xpad/Group_24455_2x_jvv3rg.png",
    color: "#f2f6f7",
  },
  {
    title: "Virtual Cards",
    det: "Spend without limits. Pay for international transactions from wherever you are, whenever you want",
    img: "https://res.cloudinary.com/gigx-technologies/image/upload/v1669272680/xpad/Group_24435_2x_cfzuga.png",
    color: "#eff1ff",
  },
  {
    title: "Bills Payment",
    det: "Handle your recurring bills with ease. Pay for subscriptions and utility bills on the fly",
    img: "https://res.cloudinary.com/gigx-technologies/image/upload/v1669272439/xpad/Group_24428_2x_dui34w.png",
    color: "#effaf3",
  },
]

const LandingPage = () => {
  const router = useRouter()
  const [mailed, setMailed] = useState(false)
  useEffect(() => {
    AOS.init()
  }, [])

  const scrollRef = useRef<any>(null)

  const scrollToSection = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" })
  }

  const [ratesArr, setRatesArr] = useState<any>([])
  const [selectedAsset, setSelectedAsset] = useState<any>(ratesArr?.[1])
  const [form, setForm] = useState({
    email: "",
  })

  const submitForm = (e: any) => {
    e.preventDefault()

    if (form.email !== "") {
      emailjs
        .send("service_om91eqh", "template_3cqyc6j", form, "Mi3eQDbdZSzfx7gNM")
        .then(
          (result: any) => {},
          (error: any) => {}
        )
      setMailed(true)
      const newRow = {
        Email: form.email,
      }
      appendSpreadsheet(newRow)
      setForm({
        ...form,
        email: "",
      })
    }
  }
  const handleChange = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  function numberWithCommas(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  // Config variables
  const SPREADSHEET_ID = "13rAQ-cvCuDKGCOaBmWu64KLiYrr4PPApxrfkd_v5VkQ"
  const SHEET_ID = 0
  // const SHEET_ID = process.env.NEXT_PUBLIC_SHEET_ID
  const GOOGLE_CLIENT_EMAIL =
    "wait-list@waiting-list-378108.iam.gserviceaccount.com"
  const GOOGLE_SERVICE_PRIVATE_KEY =
    "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCklL4ePykDgK/b\nde2463gJUTMhQ3NERe6UM4bYC+YN/WIti9W3HWv5VIF82pHmIHz7KATPvXMoiR3N\n/sGbmkDqHBovCrqSMoL+TIrvDbXVB7MxHpBgbczXBYG5A9aDh/VX/bYQRK6Uceqf\nNAqLPsFCqxkzP2NWYlpV3K2OWSXb6wCirNKbwsq+EScOl0/a0v2fETzar181f9Tj\nhWyg4giXzXSZfD05lKVN7Z0tufU7C2iJVt6qTEEV9zMB49TC/ZP1Rfsq5KpR5T9M\nZETjm680ZqJ3DbCJY9NTu/q20plv8yms9/vqfVS0yV/U0gYPmbSh2Oju7e/ewLuI\n+a7WZSrBAgMBAAECggEAROICXnt16YLjV5mYiQR4pYGoQjxOUp1oWPvl7KeyPlm2\nTTTRGoaEagLt/TknxmcDmETsGpyCxcz5vf1pv/wEPlfLt7OoWcctX0ze+2XEHGjQ\nHXda2xkpmjcyT6LlFgEB9TV5jX6JiRTSq5Zm8VevIH2nGvVlWMLqZzRpKYJzX4Lj\n2iVv9RoxQZ/zubBFPZCs6FIqWACqEg4TCGsRVNTgQ5oaWBPMlwjc90vlWYA0xxnu\nUosN/b2Fc17Vb9IGkmO1zew2llwKlbzxnF0xX2GERBlLZfPJwoF0IJxWNu3hi+ms\nAyZ+05voh1xWS0hKtn/AvDv3QHXUF1RQGiRFpJDZjwKBgQDksXyCyEz5j9tc+hqQ\nrG1cFG1XRi2FyjFc9IMp3T1nSJhHP7A3EVcqrZfKm2r5MaWmAA620WfQyWsR+Jrv\n72W3lksOL/HfOmiehxEGDnDU43WhXwiF3AzsfSH3u9I1QcfEeMhe1uYuJ4yJDx0N\nllXhqIRu8cdyOTffBt5du+zdQwKBgQC4O4YEFWMjbe8bb0PSGv/FTkOmBUvyHfBq\nCDwrerLunD5iei+VG59SWlResZjR8SJmSfj0kaJ3aWqaG35qQIyDxX085L+mHv2E\ntU1eO6GGgS21/k+Gigyg2XGf6HOGDnQxjUr7nUYJOsIkGGDDdGviR7wbFjai7jiW\ntjDXO/+1qwKBgE3A8n5cr58iBAkml6Wcmkn2P/6uQ4f7aLEcCQRQzjGxguSmiLkQ\nUjyWbMdb9BDpilJYLqiFpfBcquSfS75L/J9goAAg2PF2oF9VuLoAauhWtYW6E+G/\nIT2+ha/+0cd9p/fqwOVN11DV7XgkBVK7TFO+wdgeqBrzzUUsxqnD3RKVAoGAGPIm\nYWMr+14lZEeanVZ+j47bZh2NfQlExna6/wAsPlb1s4vr6XrGBfapOfE+Sh8mk0cs\n6KDXqOejQZ67bVO0MxuIq7gloy7LES8jfMECTB4tvFjlF8iVkSogUpC9I8fNP/Br\n2Mog85r7MrwCTjTzbr5b4CcP2tv62Z1t25cKNuUCgYBW7PsJ8RxCxy9oAby82DBg\nG3Bv5KV7dW/QvRSuKeIN2iLx3oT196E/C+HhVGY1MhWY+v/FAM0Vo07/C+4Asb1h\nesdPMUnZkqSle15NJvSLDxfTXjZpepjexcPz2wsfvNzTfQy9bGtxChyh2yHwysJl\ny9UJKY96xHn5Tr7JBmX+nQ==\n-----END PRIVATE KEY-----\n"

  // GoogleSpreadsheet Initialize
  const doc = new GoogleSpreadsheet(SPREADSHEET_ID)

  // Append Function
  const appendSpreadsheet = async (row: any) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: GOOGLE_CLIENT_EMAIL,
        private_key: GOOGLE_SERVICE_PRIVATE_KEY?.replace(/\\n/g, "\n"),
      })
      // loads document properties and worksheets
      await doc.loadInfo()

      const sheet = doc.sheetsById[SHEET_ID]
      await sheet.addRow(row)
    } catch (e) {
      // console.error("Error: ", e)
    }
  }

  const {
    data: rates,
    isFetching: ratesFetching,
    refetch: ratesFetch,
  } = useGetRatesQuery(null, { refetchOnFocus: true })

  useEffect(() => {
    setRatesArr(rates?.data)
    setSelectedAsset(rates?.data?.[1])
  }, [rates])

  // const ratesArr: any = []

  const [sendOpts, setSendOpts] = useState(false)
  const [recOpts, setRecOpts] = useState(false)
  const [toSend, setToSend] = useState<any>("")
  const [getConvert, setGetConvert] = useState<any>("")

  useEffect(() => {
    if (toSend?.length > 0) {
      setGetConvert(
        Number.isInteger(Number(toSend) * selectedAsset?.rate)
          ? Number(toSend) * selectedAsset?.rate
          : (Number(toSend) * selectedAsset?.rate).toFixed(3)
      )
    }
  }, [selectedAsset, toSend])

  return (
    <Container>
      <div className="app__banner">
        <div className="intro__grid">
          <h4 className="app__desc">
            The secure and simple way to send and receive money{" "}
            <span>across</span> borders.
          </h4>

          <div className="app__pro">
            <p>
              Make secure and affordable cross border transactions on XPAD.
              Send, Receive, Spend and Save all in one app
            </p>
          </div>

          <div className="app__download">
            <p className="download__label">Switch to the borderless wallet</p>
            <div
              className="download__sect"
              style={{ cursor: "pointer" }}
              onClick={scrollToSection}
            >
              <Download />
            </div>
          </div>
          {/* <div className="float__img">
            <img
              src="https://res.cloudinary.com/gigx-technologies/image/upload/v1669747191/xpad/particle_kvefc0.svg"
              alt=""
            />
          </div> */}
        </div>
      </div>

      <div className="app__rates">
        <div className="app__rates__header">
          <p>Exchange Rate</p>
          <p>
            {selectedAsset?.target} 1 - {selectedAsset?.source}{" "}
            {numberWithCommas(
              Number.isInteger(1 / selectedAsset?.rate)
                ? 1 / selectedAsset?.rate
                : (1 / selectedAsset?.rate).toFixed(2)
            )}
          </p>
        </div>
        <div className="app__rates__input__grid">
          <div className="rates__input">
            <div className="rates__input__cta">
              <div
                className="rates__input__cta__grid"
                style={{ cursor: "not-allowed" }}
                // onClick={() => setSendOpts(!sendOpts)}
              >
                <div className="rates__input__cta__text">
                  <p>Currency</p>
                  <p>NGN</p>
                </div>
                <div style={{ width: "12px" }} />
                {/* <img
                  src="https://res.cloudinary.com/gigx-technologies/image/upload/v1660199936/xpad-assets/arrow_down_primary_xc6kze.svg"
                  alt=""
                /> */}
              </div>
              {sendOpts && (
                <div className="rates__input__cta__dropdown">
                  {ratesArr?.map(
                    (rate: any, i: any) =>
                      rate?.source === "NGN" && (
                        <p
                          key={i}
                          className="rates__input__cta__item"
                          onClick={() => setSelectedAsset(rate?.source)}
                        >
                          {rate?.source}
                        </p>
                      )
                  )}
                </div>
              )}
            </div>
            <input
              type="text"
              placeholder="How much are you sending?"
              value={toSend.toLocaleString()}
              onChange={(e) => setToSend(e.target.value)}
            />
          </div>
          <div className="rates__input">
            <div className="rates__input__cta">
              <div
                className="rates__input__cta__grid"
                onClick={() => setRecOpts(!recOpts)}
              >
                <div className="rates__input__cta__text">
                  <p>Currency</p>
                  <p>{selectedAsset?.target}</p>
                </div>
                <img
                  src="https://res.cloudinary.com/gigx-technologies/image/upload/v1660199936/xpad-assets/arrow_down_primary_xc6kze.svg"
                  alt=""
                />
              </div>
              {recOpts && (
                <div className="rates__input__cta__dropdown">
                  {ratesArr?.map(
                    (rate: any, i: any) =>
                      rate?.source === "NGN" && (
                        <p
                          key={i}
                          className="rates__input__cta__item"
                          onClick={() => {
                            setSelectedAsset(rate)
                            setRecOpts(false)
                          }}
                        >
                          {rate?.target}
                        </p>
                      )
                  )}
                </div>
              )}
            </div>
            <input
              type="text"
              placeholder="Amount they will receive"
              // disabled
              style={{ cursor: "not-allowed", userSelect: "none" }}
              value={getConvert}
            />
          </div>
        </div>

        <div className="app__rates__btn">
          <Button
            title="Send Now"
            btnStyle={{
              borderRadius: 32,
              color: "white",
              fontSize: "14px",
              fontFamily: "Rubik-Medium",
              padding: "18px 52px",
              margin: "0 auto",
              cursor: toSend?.length > 0 ? "pointer" : "not-allowed",
            }}
            disabled={toSend?.length === 0}
            onClick={() =>
              router.push({
                pathname: "/remittance",
                query: {
                  amount: toSend,
                  asset: selectedAsset?.target,
                }, // the data
              })
            }
          />
        </div>
      </div>

      <div className="app__services">
        <h3 className="app__services__label">
          All your financial needs in one app
        </h3>

        <div className="app__services__grid">
          {needs.map((need, i) => (
            <div
              className="grid__item"
              key={i}
              data-aos={
                i === 0
                  ? "fade-down-right"
                  : i === 1
                  ? "fade-down-left"
                  : i === 2
                  ? "fade-up-right"
                  : "fade-up-left"
              }
              data-aos-duration="1500"
              style={{ backgroundColor: need.color }}
            >
              <div className="grid__item__text">
                <h3>{need.title}</h3>
                <p>{need.det}</p>
              </div>

              <div className="float__img">
                <img src={need.img} alt="showcase Image" />
              </div>
            </div>
          ))}
        </div>

        <div className="waitlist" ref={scrollRef}>
          <h3 className="app__services__label">
            Hold on tight. The Borderless Wallet is on the way
          </h3>
          <div className="app__pro">
            <p>
              XPAD 2.0 comes with exciting features that would make financial
              transactions easy for you within and outside of Africa. We are
              working smart and fast to bring you the best version of our
              product yet.
            </p>
          </div>
          <div className="waitlist__banner">
            <div className="waitlist__image">
              <img
                src="https://res.cloudinary.com/gigx-technologies/image/upload/v1676879315/xpad/Market_launch-pana_mnoncu.svg"
                alt=""
                style={{
                  width: "400px",
                }}
              />
            </div>
            {!mailed ? (
              <div className="waitlist__form">
                <p className="form__label">
                  Join Seyi, Harry, and other tribe members on the waitlist.
                </p>
                <p className="form__extra">
                  Enter your email and we'll send you a special invite once we
                  are live.
                </p>
                <form className="waitlist__input" onSubmit={submitForm}>
                  <input
                    name="email"
                    type="email"
                    className="form-input form-field-contact"
                    placeholder="Email Address"
                    onChange={handleChange}
                    value={form?.email}
                  />
                  <button type="submit" style={{ cursor: "pointer" }}>
                    <p style={{ color: "#fff" }}>Submit</p>
                  </button>
                </form>
              </div>
            ) : (
              <div className="waitlist__form waitlist__formed">
                <img
                  src="https://res.cloudinary.com/gigx-technologies/image/upload/v1678350140/xpad/tickkkkk_d9bygy.svg"
                  alt=""
                />
                <div>
                  <p className="form__label">Subscribed successfully</p>
                  <p className="form__extra">
                    We've received your email and you have successfully been
                    added to our waitlist. You will be one of the first to know
                    when we launch.
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="app__cta" data-aos="flip-up" data-aos-duration="1500">
          <div className="app__cta__left">
            <h3>
              Embrace hassle free <br />
              cross border transactions
            </h3>

            <p className="app__cta__text">
              Get started right away . Need support? <br /> We are here for you,
              everyday
            </p>
            <div
              className="download__sect"
              style={{ cursor: "pointer" }}
              onClick={scrollToSection}
            >
              <Download white />
            </div>
          </div>
          <div className="app__cta__right">
            <img
              src="https://res.cloudinary.com/gigx-technologies/image/upload/v1669272178/xpad/Image_5_2x_je1rbb.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </Container>
  )
}
export default LandingPage
